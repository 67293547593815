import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-8001_HD/NavButtons';
import CompareIndoor from 'components/Products/Compare/CompareIndoorFlyout';
import TableFeatures from 'components/Indoor_Cameras/IN-8001_HD/TableFeatures';
import IndoorData from 'components/Products/Flyouts/Graphs/IndoorGraphsFlyout';
import SmartCards from 'components/Products/Flyouts/Cards/8001Cards';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8001 Full HD",
  "path": "/Indoor_Cameras/IN-8001_HD/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-8001 Full HD Indoor IP Camera",
  "image": "../P_SearchThumb_IN-8001HD.png",
  "social": "/images/Search/P_SearchThumb_IN-8001HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8001HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-8001 Full HD' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-8001 Full HD Indoor IP Camera' image='/images/Search/P_SearchThumb_IN-8001HD.png' twitter='/images/Search/P_SearchThumb_IN-8001HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8001_HD/' locationFR='/fr/Indoor_Cameras/IN-8001_HD/' crumbLabel="IN-8001" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-8001-full-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-8001-full-hd",
        "aria-label": "in 8001 full hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8001 Full HD`}</h1>
    <CompareIndoor mdxType="CompareIndoor" />
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/47311/INSTAR_IN-8001_FHD.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACA//EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB6kdoxJNi/8QAGRABAQADAQAAAAAAAAAAAAAAAQIAEBEh/9oACAEBAAEFAq6A0zc+4a//xAAXEQEAAwAAAAAAAAAAAAAAAAAAERJB/9oACAEDAQE/Aaah/8QAFREBAQAAAAAAAAAAAAAAAAAAEBL/2gAIAQIBAT8Bo//EABoQAAICAwAAAAAAAAAAAAAAAAERADEgISL/2gAIAQEABj8CADhR6UoVvD//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMRBBYf/aAAgBAQABPyHhWZkPXvkX3cRGjS32vGW49Z//2gAMAwEAAgADAAAAEPwP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREA/9oACAEDAQE/EEWHTv/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxAeZLf/xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMUFhgf/aAAgBAQABPxBabMdaCFy73AgWFl/cSvVA3KuvuIHDOUFnpcBym/M//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/e4706/INSTAR_IN-8001_FHD.avif 230w", "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/d1af7/INSTAR_IN-8001_FHD.avif 460w", "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/7f308/INSTAR_IN-8001_FHD.avif 920w", "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/d8ca6/INSTAR_IN-8001_FHD.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/a0b58/INSTAR_IN-8001_FHD.webp 230w", "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/bc10c/INSTAR_IN-8001_FHD.webp 460w", "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/966d8/INSTAR_IN-8001_FHD.webp 920w", "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/260c2/INSTAR_IN-8001_FHD.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/e83b4/INSTAR_IN-8001_FHD.jpg 230w", "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/e41a8/INSTAR_IN-8001_FHD.jpg 460w", "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/4d836/INSTAR_IN-8001_FHD.jpg 920w", "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/47311/INSTAR_IN-8001_FHD.jpg 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2c33f4cb79f256e4b3392bbcf3bd4252/4d836/INSTAR_IN-8001_FHD.jpg",
              "alt": "INSTAR IN-8001 Full HD",
              "title": "INSTAR IN-8001 Full HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`INSTAR IN-8001 Full HD Night Vision Surveillance Camera (LAN/WLAN) Small but nice indoor camera with invisible 940nm infrared LEDs, PIR sensor and SD card. 3 second pre-recording before alarm. Smarthome compatibility thanks to Homekit, Alexa Skill, IFTTT and CGI & MQTT Interface. The compact design of this camera was completely new developed and makes it ideal for discreet surveillance task. The 6-times 940nm night vision LEDs are invisible to the human eye, making the IN-8001 a suitable baby monitor.`}</p>
    <IndoorData mdxType="IndoorData" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`This camera is running an embedded Linux operating system and a Webserver which supports TLS/SSL encryption for security and privacy. It provides a built-in, modern HTML5 based Web User Interface (WebUI) with focus on usability and simplicity, supporting all recent devices (desktop, tablet, smartphone) and operating systems (Windows, MacOS, Linux,…). Thanks to the built-in Camera Setup Wizard and the (built-in) help function, INSTAR users can enjoy a very easy initial set-up of the IP camera.`}</p>
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Integrated PIR heat detector for advanced motion detection`}</li>
      <li parentName="ul">{`Wide Dynamic Range (WDR) 1080p `}<Link to="/Indoor_Cameras/IN-8001_HD/Technical_Specifications/" mdxType="Link">{`Panasonic CMOS Sensor`}</Link></li>
      <li parentName="ul">{`Built-in microphone incl. noise detection with adjustable max. level`}</li>
      <li parentName="ul">{`Built-in PIR motion detector to avoid false alarms`}</li>
      <li parentName="ul">{`6 x 940nm IR night vision LEDs invisible to the human eye`}</li>
      <li parentName="ul">{`Suitable for wall and ceiling mounting`}</li>
      <li parentName="ul">{`MicroSD memory cards up to 128GB possible. (16GB already installed ex works)`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/573d3/IN-8001_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABVElEQVQoz5VSy07CQBTlf9iqPyAr+QC6UaP+AP4DLvkZkCXxAwiJaWy7MNoHldBRSjvtzLSdxzV0gGJiTDy7mTnn3nPPnRbUUErB/9H6462qcVy3qirGWMV5I2aMLZfLLMt4favZGOOry+t+/36TbDRVSoHQynXfPd9rxNPp9PbmznGcOI5rkgSACEWnJ2fdi+4qinRFIcTX+nOxCPwgkFLtxOPxY69nWJaFEAIAIQQAIITa7fZ5pxPtxVLKMPRty/R8T7vbikejkWEYtm2bppnnOWMMAAghg4fBcDhc13YAgAsR+G/Oy6zuLHdi13Unk0kQBPP5PMsySqnun6QJQpGutU2Lc999fZ49heFHM7MQoixLjDEhpCgKvg+TUpqm6fEilVJ5Tg7HH6tSNXYmK54kCUKIELJPW1JK43iNMW5m/vWfCCGKojhEoAmUUpzhFKda/A27CSz3rDoGtgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/e4706/IN-8001_Overview_width1650.avif 230w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/d1af7/IN-8001_Overview_width1650.avif 460w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/7f308/IN-8001_Overview_width1650.avif 920w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/e1c99/IN-8001_Overview_width1650.avif 1380w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/e02f7/IN-8001_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/a0b58/IN-8001_Overview_width1650.webp 230w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/bc10c/IN-8001_Overview_width1650.webp 460w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/966d8/IN-8001_Overview_width1650.webp 920w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/445df/IN-8001_Overview_width1650.webp 1380w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/44758/IN-8001_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/81c8e/IN-8001_Overview_width1650.png 230w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/08a84/IN-8001_Overview_width1650.png 460w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/c0255/IN-8001_Overview_width1650.png 920w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/b1001/IN-8001_Overview_width1650.png 1380w", "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/573d3/IN-8001_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b0fd205fac03320c7edaf8c7b2f6daa/c0255/IN-8001_Overview_width1650.png",
              "alt": "INSTAR IN-8001 Full HD",
              "title": "INSTAR IN-8001 Full HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`RJ-45 Ethernet port for an easy integration into your 100Mbit home network`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1080p_Series/Network/IP_Config/" mdxType="Link">DHCP</Link> and <Link to="/Web_User_Interface/1080p_Series/Network/UPnP/" mdxType="Link">UPnP</Link> support for an automatic network integration
      </li>
      <li parentName="ul">{`Integrated personal, free `}<Link to="/Internet_Access/The_DDNS_Service/" mdxType="Link">{`DDNS (`}{`"`}{`Internet`}{`"`}{`) Address`}</Link>{` and `}<Link to="/Web_User_Interface/1080p_Series/Network/Internet_Access/" mdxType="Link">{`P2P User ID`}</Link>{` to access your camera through the Internet`}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1080p_Series/" mdxType="Link">Web user interface</Link> allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1080p_Series/System/User/" mdxType="Link">Multi user management</Link> and simultaneous login for 3 users
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1080p_Series/Alarm/Areas/" mdxType="Link">Software-based motion</Link> and <Link to="/Web_User_Interface/1080p_Series/Alarm/Actions/" mdxType="Link">audio</Link> detection and <Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link"> email notification</Link> with attached snapshots
      </li>
      <li parentName="ul">{`Record videos directly to an `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Internal SD Card`}</Link></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-8001 is the newest Full HD PIR Indoor IP Camera from INSTAR. With a built-in passive infrared (PIR) motion sensor and four separately configurable alarm areas that can be linked together, the camera prevents false alarms and notifies you only when a real alarm has been triggered.`}</p>
    <SmartCards mdxType="SmartCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Equipped with one of the latest Panasonic Full HD CMOS image sensor, which features low noise, high speed and new Wide Dynamic Range (WDR) functionality, the IN-8001 is able to achieve the best image quality in daylight as well as clear image in night vision mode with the help of its high-power LEDs. Hence, the IN-8001 is able to deliver accurate motion detection and alarm notification.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      